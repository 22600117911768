.work {
    img {
        // width: 100%;
        // height: 500px;
        overflow: hidden;
      }
      .col-md-2 {
          position: relative;
        height: 100vh;
        background-color: black;
        filter: grayscale(100%);
        &:hover {
          filter: grayscale(0%);
          transition: 0.4s;
          cursor: pointer;
        }
        &:first-of-type {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/assets/mini.jpg);
            opacity: 0.3;
            background-repeat: no-repeat;
            background-position: 10% 0;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        &:nth-of-type(2) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/assets/scripture.jpg);
            opacity: 0.3;
            background-repeat: no-repeat;
            background-position: 35% 0;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        &:nth-of-type(3) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/assets/doll.jpg);
            opacity: 0.3;
            background-repeat: no-repeat;
            background-position: 35% 0;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        &:nth-of-type(4) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/assets/reflection.jpg);
            opacity: 0.3;
            background-repeat: no-repeat;
            background-position: 50% 0;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        &:nth-of-type(5) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/assets/monk.jpg);
            opacity: 0.3;
            background-repeat: no-repeat;
            background-position: 20% 0;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        &:last-of-type {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/assets/010.jpg);
            opacity: 0.3;
            background-repeat: no-repeat;
            background-position: 50% 0;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
      }
      
      h3 {
        font-family: Mories;
        color: white;
        position: relative;
        left: 40%;
        transform: rotate(-90deg);
        white-space: nowrap;
      }
      .bottom {
        top: 75%;
      }
      .middle {
        top: 50%;
      }
}

// @media (max-width: 767px) {
//     .work {
//         .middle, .bottom {
//             transform: translateY(-50%);
//             top: 50%;
//           }
//           h3 {
//             left: 0;
//             transform: rotate(0deg);
//             text-align: center;
//             font-size: 65px;
//           }
//     }
//   }
  
//   @media (max-width: 630px) {
//     .work {
//         h3 {
//             font-size: 45px;
//           }
//     }
//   }
  
//   @media (max-width: 460px) {
//     .work {
//         h3 {
//             font-size: 35px;
//           }
//     }
//   }
  
//   @media (max-width: 360px) {
//     .work {
//         h3 {
//             font-size: 25px;
//           }
//     }
//   }

@media (max-width: 1070px) {
    .work {
          .col-md-2 {
            height: 750px;
          }
          .bottom {
            top: 80%;
          }
          .middle {
            top: 60%;
          }
    }
  }
  
  @media (max-width: 815px) {
    .work {
        .col-md-2 {
          height: 650px;
        }
        .bottom {
          top: 80%;
        }
        .middle {
          top: 60%;
        }
  }
  }

  @media (max-width: 767px) {
    .work {
        .middle {
            transform: translateY(-50%) !important;
            top: 50% !important;
          }
          .bottom {
            transform: translateY(-50%) !important;
            top: 50% !important;
          }
          h3 {
            left: 0;
            transform: rotate(0deg);
            text-align: center;
            font-size: 50px;
          }
    }
  }
  
  @media (max-width: 685px) {
    .work {
        .col-md-2 {
          height: 550px;
        }
        .bottom {
          top: 80%;
        }
        .middle {
          top: 60%;
        }
        h3 {
            font-size: 40px;
          }
  }
  }
  
  @media (max-width: 550px) {
    .work {
        .col-md-2 {
          height: 450px;
        }
        .bottom {
          top: 80%;
        }
        .middle {
          top: 60%;
        }
        h3 {
            font-size: 30px;
          }
  }
  }
  
  @media (max-width: 430px) {
    .work {
        .col-md-2 {
          height: 350px;
        }
        .bottom {
          top: 80%;
        }
        .middle {
          top: 60%;
        }
        h3 {
            font-size: 25px;
          }
  }
  }
  
  @media (max-width: 360px) {
    .work {
        .col-md-2 {
            height: 250px;
        }
        .bottom {
          top: 80%;
        }
        .middle {
          top: 60%;
        }
  }
  }