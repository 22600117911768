.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Mories';
  src: url('assets/Mories.eot');
  src: local('Mories'),
      url('assets/Mories.eot?#iefix') format('embedded-opentype'),
      url('assets/Mories.woff2') format('woff2'),
      url('assets/Mories.woff') format('woff'),
      url('assets/Mories.ttf') format('truetype'),
      url('assets/Mories.svg#Mories') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/CenturyGothic.eot');
  src: url('assets/CenturyGothic.eot?#iefix') format('embedded-opentype'),
      url('assets/CenturyGothic.woff2') format('woff2'),
      url('assets/CenturyGothic.woff') format('woff'),
      url('assets/CenturyGothic.ttf') format('truetype'),
      url('assets/CenturyGothic.svg#CenturyGothic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.mr-auto {
  margin-left: auto !important;
}

body {
  overflow-x: hidden;
}

* {
  font-family: 'Mories';
  color: black;
}

.content-read {
  color: black !important;
  font-size: 20px !important;
}

@media (max-width: 1070px) {
  .content-read {
    font-size: 19px !important;
  }
}

@media (max-width: 815px) {
  .content-read {
    font-size: 18px !important;
  }
}

@media (max-width: 685px) {
  .content-read {
    font-size: 17px !important;
  }
}

@media (max-width: 550px) {
  .content-read {
    font-size: 16px !important;
  }
}

@media (max-width: 430px) {
  .content-read {
    font-size: 15px !important;
  }
}

@media (max-width: 360px) {
  .content-read {
    font-size: 14px !important;
  }
}
