section {
    width: 100%;
    display: inline-block;
    background: #333;
    height: 50vh;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-decoration: underline;
    font-family: 'Century Gothic' !important;
  }
  
  .footer-distributed{
    //   margin-top: 100px;
    z-index: 9999999999;
    position: relative;
      background: black;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      font-size: 18px;
      font-family: 'Century Gothic', sans-serif;
      padding: 55px 50px;
  }
  
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
      display: inline-block;
      font-family: 'Century Gothic' !important;
      vertical-align: top;
  }
  
  /* Footer left */
  
  .footer-distributed .footer-left{
    font-family: 'Century Gothic' !important;
      width: 40%;
  }
  
  /* The company logo */
  
  .footer-distributed h3{
      color:  white;
      font-size: 36px;
      margin: 0;
  }
  
  .footer-distributed h3 span{
      color:  white;
  }
  
  /* Footer links */
  
  .footer-distributed .footer-links{
      color:  white;
      font-family: 'Century Gothic' !important;
      margin: 20px 0 12px;
      padding: 0;
  }
  
  .footer-distributed .footer-links a{
      display:inline-block;
      margin-right: 5px;
      line-height: 1.8;
      font-family: 'Century Gothic' !important;
    font-weight:400;
      text-decoration: none;
      color:  inherit;
      transition: ease-in-out .3s;
      &:hover {
        //   color:  #8CC640;
      }
  }
  
  .footer-distributed .footer-company-name{
      color:  white;
      font-size: 14px;
      font-family: 'Century Gothic' !important;
      font-weight: normal;
      margin: 0;
  }
  
  /* Footer Center */
  
  .footer-distributed .footer-center{
      width: 35%;
      font-family: 'Century Gothic' !important;
  }
  
  .footer-distributed .footer-center i{
      background-color:  #33383b;
      color: white;
      font-size: 25px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      text-align: center;
      line-height: 42px;
      margin: 10px 15px;
      vertical-align: middle;
  }
  
  .footer-distributed .footer-center i.fa-envelope{
      font-size: 17px;
      line-height: 38px;
  }
  
  .footer-distributed .footer-center p{
      font-size: 16px;
      display: inline-block;
      color: white;
    font-weight:400;
    font-family: 'Century Gothic' !important;
      vertical-align: middle;
      margin:0;
  }
  
  .footer-distributed .footer-center p span{
      display:block;
      font-weight: normal;
      font-size:16px;
      font-family: 'Century Gothic' !important;
      line-height:2;
  }
  
  .footer-distributed .footer-center p a{
      color:  white;
      font-family: 'Century Gothic' !important;
      text-decoration: none;;
  }
  
  .footer-distributed .footer-links a:before {
    content: "|";
    font-weight:300;
    font-size: 18px;
    left: 0;
    font-family: 'Century Gothic' !important;
    color: white;
    display: inline-block;
    padding-right: 5px;
  }
  
  .footer-distributed .footer-links .link-1:before {
    content: none;
    font-family: 'Century Gothic' !important;
  }
  
  /* Footer Right */
  
  .footer-distributed .footer-right{
      width: 20%;
      font-family: 'Century Gothic' !important;
  }
  
  .footer-distributed .footer-company-about{
      line-height: 20px;
      color:  white;
      font-size: 13px;
      font-weight: normal;
      font-family: 'Century Gothic' !important;
      margin: 0;
  }
  
  .footer-distributed .footer-company-about span{
      display: block;
      color:  white;
      font-size: 14px;
      font-family: 'Century Gothic' !important;
      font-weight: bold;
      margin-bottom: 20px;
  }
  
  .footer-distributed .footer-icons{
      margin-top: 25px;
      font-family: 'Century Gothic' !important;
  }
  
  .footer-distributed .footer-icons a{
      display: inline-block;
      width: 35px;
      font-family: 'Century Gothic' !important;
      height: 35px;
      cursor: pointer;
      background-color:  #33383b;
      border-radius: 2px;
  
      font-size: 20px;
      color: white;
      text-align: center;
      line-height: 35px;
  
      margin-right: 3px;
      font-family: 'Century Gothic' !important;
      margin-bottom: 5px;
      transition: ease-in-out .3s;
      &:hover {
        //   background-color:  #8CC640;
      }
  }
  
  .smallS {
    //   display: none;
      font-family: 'Century Gothic' !important;
  }
  
  /* If you don't want the footer to be responsive, remove these media queries */
  
  @media (max-width: 880px) {
      .smallS {
          display: block;
      }
      .bigS {
          display: none;
      }
      .footer-distributed{
          font: bold 14px sans-serif;
      }
  
      .footer-distributed .footer-left,
      .footer-distributed .footer-center,
      .footer-distributed .footer-right{
          display: block;
          width: 100%;
          margin-bottom: 40px;
          text-align: center;
      }
  
      .footer-distributed .footer-center i{
          margin-left: 0;
      }
  
  }
  
  .hb {
      margin-top: 15px;
      color: white;
      font-family: 'Century Gothic' !important;
      padding-bottom: 0;
      margin-bottom: 0;
  }
  .hb-logo {
    font-family: 'Century Gothic' !important;
      img {
          margin-top: 10px;
          width: 200px;
      }
  }