.about {
    padding-top: 100px;
    padding-bottom: 100px;
    img {
        width: 300px;
        border-radius: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        font-family: 'Century Gothic';
          margin-top: 30px;
          text-align: center;
          span {
            
        font-family: 'Century Gothic';
          }
      }
      
      .read-more {
        font-family: 'Mories';
        cursor: pointer;
      }
}

@media (max-width: 1070px) {
    .about {
        img {
            width: 280px;
          }
    }
  }
  
  @media (max-width: 815px) {
    .about {
        img {
            width: 260px;
          }
    }
  }
  
  @media (max-width: 685px) {
    .about {
        img {
            width: 240px;
          }
    }
  }
  
  @media (max-width: 550px) {
    .about {
        img {
            width: 220px;
          }
    }
  }
  
  @media (max-width: 430px) {
    .about {
        img {
            width: 210px;
          }
    }
  }
  
  @media (max-width: 360px) {
    .about {
        img {
            width: 200px;
          }
    }
  }

// @media (max-width: 430px) {
//     .about {
//         img {
//             width: 250px;
//           }
//     }
//   }